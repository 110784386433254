.burger-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    justify-content: space-between;
    height: calc(100vh - 100px);
    z-index: 1000 ;
}

.burger__internal-nav {
    width: 100%;
}

.burger__internal-nav__item {
    width: 100%;
    padding: 16px;
    text-align: center;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #0000001F;
    cursor: pointer;
}

.burger__internal-nav__item:hover {
    background-color: #FFFFFF;
}

.burger__bottom-part {
    margin-top: 48px;
}
.burger__buttons {
    margin: auto;
    justify-content: center;
    display: flex;
    gap: 12px;
    margin-bottom: 48px;
    width: 560px;
    @media screen and (max-width: 767px) {
        max-width: 560px;
        width: 100%;
        padding: 0 24px;
    }
}

.burger__socials {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    max-width: 560px;

    @media screen and (max-width: 767px) {
        max-width: 250px;
    }

}