
.social {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 40px;
    width: 100%;
}

.social__item {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.social__telegram {
    background-image: url("./tg.png");
}

.social__discord {
    background-image: url("./discord.png");
}

.social__github {
    background-image: url("./github.png");
}

.social__linkedin {
    background-image: url("./linkedin.png");
}
