body {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}


header {

}

div {
    box-sizing: border-box;
}

footer {
    box-sizing: border-box;
}

header {
    box-sizing: border-box;
}

input {
    box-sizing: border-box;
}

textarea {
    box-sizing: border-box;
}