
.drop-line__container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 16px;
  background: #FFF0D3;

}

.drop-line__drop {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./drop-flat.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 12.19px;
  height: 16px;
}