.button-new {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    outline: 0;
    border: 0;
}

.button-new:hover {
    background-color: #252BA5;
    color: #FFF6E3;
}

.button-new-size-m {
    font-family: "PP Telegraf Ultrabold", sans-serif;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0.4000000059604645px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;

    @media screen and (max-width: 1365px) {
        font-size: 22px;
        line-height: 24px;
    }
    @media screen and (max-width: 767px) {
        font-size: 19px;
        line-height: 20px;  
    }
}

.button-new-size-s {
    padding: 10px 24px 12px 24px;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;

    @media screen and (max-width: 1365px) {
        font-size: 17px;
        line-height: 20px;
    }
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;  
    }
}

.button-new-width-content {
    width: max-content;
}

.button-new-width-max {
    width: 100%;
}


.button-new-black {
    background-color: #22232B;
    color: #FFF6E3;
}

.button-new-blue {
    background-color: #252BA5;
    color: #FFF6E3;
}

.button-new-white {
    background-color: white;
    color: #22232B;
    border: 2px solid #22232B;
}

.button-new-transparent {
    background-color: inherit;
    color: #22232B;
    border: 2px solid #22232B;
}

.button-new-text {
    margin-left: 16px;
}

.button-new-img {
    width: 22px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.button-new-img-drop {
    background-image: url('../../drop.svg');
}

.button-new-img-megaphone {
    background-image: url('../../megaphone.svg');
}

.button-new-img-heart {
    background-image: url('../../heart.svg');
}