.contact-form__container {
    background-color: #FFFFFF;
    padding: 80px;

    @media screen and (max-width: 374px) {
        padding: 40px 12px;
    }
}

.contact-form__box {
    display: flex;
    flex-direction: column;
    max-width: 434px;
}

.contact-form__box .landing-content-box__header {
    text-align: center;
    margin-bottom: 56px;
    font-size: 64px;
    text-align: start;
}

.contact-form__text {
    margin: auto;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 40px;

}

.contact-form__form {
    margin: auto;
    width: 100%;

}

input {
    box-sizing: border-box;
}

.contact-form__input {
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    border: 1px solid #000000;
    background-color: #FFF0D3;
    font-family: "PP Telegraf Regular", sans-serif;
    font-size: 20px;
}


.contact-form__input:focus-visible {
    border: 2px solid #000AFF;
    outline: none;
}

.contact-form__upper-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;

}

.contact-form__input-name {
    width: 100%;
    height: 44px;
}

.contact-form__input-email {
    width: 100%;
    height: 44px;
}

.contact-form__input-message {
    text-align: start;
    width: 100%;
    height: 100px;
    margin-bottom: 40px;
}

.contact-form__button {
    box-sizing: border-box;
    margin: auto;
    width: 100%;
}


.wrapper {
    background-color: #FFF0D3;
    border-radius: 80px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 100%;
    height: 100%;
    gap: 128px;

    padding: 120px 100px 100px 100px;

    @media screen and (max-width: 1365px) {
        padding: 146px 16px;
        border-radius: 24px;
    }
}

.info {
    position: relative;
}

.info-wrapper {
    display: flex;
    gap: 16px;
}

.info-block {
    width: 249px;
    height: 168px;
    border-radius: 24px;
    padding: 24px;
    background-color: #FFD788;

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.title-info-block {
    font-family: "PP Telegraf Ultrabold", sans-serif;
    font-size: 28px;
    font-weight: 800;
    text-align: start;
}

.description {
    font-family: "PP Telegraf Regular", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
}

.currency {
    font-family: "PP Telegraf Ultrabold", sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0px;
}

.surprise {
    position: absolute;
    left: 12px;
    width: 480px;
    top: -436px;
}

.patron {
    background-color: #C2C7EF;
}

@media screen and (max-width: 1444px) {
    .contact-form__box .landing-content-box__header {
        font-size: 58px;
    }

    .contact-form__container {
        padding: 80px 44px;
    }
}


@media screen and (max-width: 1365px) {
    .contact-form__container {
        padding: 80px 32px;
    }

    .contact-form__box .landing-content-box__header {
        font-size: 52px;
    }
}

@media screen and (max-width: 1100px) {
    .contact-form__container {
        padding: 80px 40px;
    }


    .wrapper {
        flex-direction: column-reverse;
        align-items: center;
        gap: 24px;
        padding: 126px 80px 80px 80px;
    }

    .contact-form__box {
        max-width: 512px;
    }

    .info {
        padding-top: 250px;
        width: 512px;
    }

    .surprise {
        top: -186px;
    }

    .contact-form__box .landing-content-box__header {
        text-align: center;
        font-size: 64px;
        padding-bottom: 190px;
    }

    .info-wrapper {
        position: absolute;
        top: 470px
    }
}


@media screen and (max-width: 767px) {
    .contact-form__box .landing-content-box__header {
        text-align: center;
        font-size: 64px;
        padding-bottom: 200px;
        line-height: 56px;
    }

    .wrapper {
        padding: 126px 40px 80px 40px;

    }
}

@media screen and (max-width: 660px) {
    .info-wrapper {
        position: absolute;
        flex-direction: column;
        top: 470px;
        padding: 0px;
    }

    .info-block {
        width: 100%;
        height: 100%;
        padding: 16px;
        border-radius: 16px;

        &__header {
            padding-bottom: 16px;
        }
    }

    .wrapper {
        padding: 126px 20px 20px 20px;

    }

    .contact-form__box .landing-content-box__header {
        font-size: 58px;
        line-height: 56px;
        padding-bottom: 360px;
    }

    .contact-form__container {
        padding: 80px 16px;
    }
}

@media screen and (max-width: 590px) {
    .info-wrapper {
        padding: 0 40px;
    }
}

@media screen and (max-width: 500px) {
    .info-wrapper {
        padding: 0;
        top: 220px;
        padding-top: 20px;
    }

    .info {
        padding-top: 100px;
        width: 312px;
    }

    .surprise {
        left: 0;
        width: 312px;
        top: -160px;
    }

    .contact-form__box .landing-content-box__header {
        font-size: 32px;
        line-height: 36px;
        padding-bottom: 310px;
    }

    .title-info-block {
        font-size: 19px;
        line-height: 20px;
    }

    .description {
        font-size: 16px;
        line-height: 12px;
    }
}

@media screen and (max-width: 374px) {
    .wrapper {
        padding: 126px 16px 24px 16px;

    }

    .title-info-block {
        line-height: 16px;
    }

    .info {
        padding-top: 100px;
        width: 272px;
    }


    .surprise {
        max-width: 272px;
        top: -150px;
    }

    .info-wrapper {
        top: 256px;
        padding: 0 6px;
    }
}