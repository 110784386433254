
.creator-container {
    background: #FFF0D3;
    padding: 120px 160px 0 160px;
    overflow: hidden;

    @media screen and (max-width: 1919px) {
        padding: 120px 195px 0 195px;
    }
    @media screen and (max-width: 1365px) {
        padding: 80px 64px 0 64px;
    }
    @media screen and (max-width: 767px) {
        padding: 56px 24px 0 24px;
    }
    @media screen and (max-width: 374px) {
        padding: 48px 24px 0 24px;
    }
}

.creator-box {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}


.creator-right {
    display: flex;
    flex-direction: column;
    
    margin-bottom: 282px;

    @media screen and (max-width: 1919px) {
        margin-bottom: 200px;
    }
    @media screen and (max-width: 1365px) {
        width: 290px;
        margin-bottom: 108px;
    }
    @media screen and (max-width: 766px) {
        margin-bottom: 48px;
    }

}

.creator-content {
    width: 400px;
    
    @media screen and (max-width: 1365px) {
        width: 290px;
    }
}

.creator-content__text {
    margin-top: 24px;
    margin-bottom: 72px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;

    @media screen and (max-width: 1919px) {
        margin-top: 24px;
        margin-bottom: 72px;    
    }
    @media screen and (max-width: 1365px) {
        font-size: 17px;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 48px;   
    }
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 24px;   
    }
    @media screen and (max-width: 374px) {
        margin-top: 8px;
        margin-bottom: 24px;   
    }
}

.creator-content__text ol {
    padding-inline-start: 25px;
}


.creator-content__text li {
    padding-left: 15px;
}

.creator-content__text span {
    
    font-style: italic;
    font-weight: 400;
    text-align: left;
    color: #0000007A;

}

.creator-left {
    display: flex;
    width: 528px;
    height: 50px;
    justify-content: left;
    align-items: start;

    @media screen and (max-width: 1365px) {
        order: 1;
        justify-content: right;
    }
    @media screen and (max-width: 767px) {
        height: 420px;
        justify-content: center;
    }
}

.creator-widget-container {
    width: 410px;
    max-width: 410px;
    height: 845px;
    max-height: 845px;
    background-image: url("./requestWidget.svg");
    background-size: cover;

    @media screen and (max-width: 1365px) {
        width: 286px;
        height: 594px;
    }
}