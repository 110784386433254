
.landing-content-box__header {
    font-family: "PP Telegraf Ultrabold", sans-serif;
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0;
    text-align: left;

    @media screen and (max-width: 1919px) {
        font-size: 64px;
        line-height: 64px;
    }
    @media screen and (max-width: 1365px) {
        font-size: 52px;
        line-height: 56px;
    }
    @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 36px;
    }
    @media screen and (max-width: 374px) {
        font-size: 32px;
        line-height: 36px;
    }
}

