nav {
    display: flex;
}

.nav-item {
    font-family: "PP Telegraf Semibold", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000;
    height: 36px;
    border-radius: 4px;
    font-size: 20px;
    margin-left: 8px;
    padding: 2px 24px;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
}

a {
    display: block;
    
    padding: 6px 24px 4px 24px;

    text-decoration: none;
    color: black;
    
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}
    
@media screen and (max-width: 767px) {
    nav {
        display: none;
    }
}