.how-it-works-container {
    padding-bottom: 88px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1919px) {
        padding: 0 24px 88px 24px;
    }
    @media screen and (max-width: 1365px) {
        padding: 0 24px 88px 24px;
    }
    @media screen and (max-width: 767px) {
        padding: 0 12px 88px 12px;
        width: 100%;
        border-radius: 24px;
    }
}

.how-it-works-frame {
    background-color: #FFF6E3;
    padding: 120px 76px 60px 76px;
    border-radius: 80px;
    box-shadow: 16px 20px 0px 0px #0000000F;
    box-shadow: 16px 16px 18px 0px #0000001A;
    align-content: center;

    @media screen and (max-width: 1365px) {
        padding: 72px 40px;
    }
    @media screen and (max-width: 767px) {
        padding: 32px 16px;
        width: 100%;
        border-radius: 24px;
    }
    
}

.how-it-works-content{
    display: flex;
    @media screen and (max-width: 1365px) {
        flex-direction: column;
    }
}

.how-it-works-content .landing-content-box__header {
    width: 33%;

    @media screen and (max-width: 1365px) {
        width: 100%;
        font-size: 52px;
        margin-bottom: 32px;
    }
    @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 24px;
    }
}

.how-it-works-content .landing-content-box__header div {
    @media screen and (max-width: 1365px) {
        display: inline;
    }
}

.how-it-works-content-points {
    margin-left: 32px;
}

.hiw-points-item {
    display: flex;
}

.how-it-works-content-points ol {
    margin-block-start: 0;
    padding-inline-start: 0px;
}


.how-it-works-content-points li {
    padding-left: 15px;
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;

    @media screen and (max-width: 1365px) {
        font-size: 22px;
        line-height: 24px;
    }
}

.how-it-works-content-points li span {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;

    @media screen and (max-width: 1365px) {
        font-size: 17px;
        line-height: 24px;
    }
}

.how-it-works-flow {
    margin: auto;
    margin-top: 80px;
    background-image: url("./Frame\ 81513906.png");
   
    background-size: contain;
    background-repeat: no-repeat;
    width: 1368px;
    height: 400px;

    @media screen and (max-width: 1919px) {
        width: 1126px;
        height: 300px;
    }
    @media screen and (max-width: 1365px) {
        background-image: url("./flow_mobile.svg");
        width: 640px;
        height: 650px;
    }
    @media screen and (max-width: 767px) {
        background-image: url("./flow_narrowScreen.svg");
        width: 320px;
        height: 967px;
        margin-top: 40px;
    }
    @media screen and (max-width: 374px) {
        width: 272px;
        height: 850px;
    }

}