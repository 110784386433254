footer {
    background-color: #FFFFFF;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 120px 104px;
}

.footer-logo {
    width: 330px;
    height: 100px;
    background-image: url("../../drizzleLogo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.footer-nav {
    width: 560px;
    align-content: center;
}

.footer-internal-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.footer-internal-nav-item {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    padding: 8px 13px;
}

.footer-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.footer__button-divider {
    width: 12px;
}

.footer-copyright {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #0000007A;
}

@media screen and (max-width: 1919px) {
    .footer-logo {
        width: 210px;
        height: 64px;
    }
}

@media screen and (max-width: 1365px) {
    footer {
        flex-direction: column;
        padding: 80px 80px 40px 80px;
    }

    .footer-logo {
        order: 1;
        margin: auto;
        width: 210px;
        height: 64px;
        margin-bottom: 56px;
    }

    .footer-nav {
        width: 608px;
        order: 3;
        margin: auto;
    }

    .footer-social {
        order: 2;
        margin: auto;
        margin-bottom: 56px;
    }
}

@media screen and (max-width: 767px) {
    footer {
        flex-direction: column;
        padding: 24px;
    }

    .footer-logo {
        order: 1;
        margin: auto;
        width: 210px;
        height: 64px;
        margin-bottom: 56px;
    }

    .footer-nav {
        width: 327px;
        order: 3;
        margin: auto;
    }

    .footer-social {
        order: 2;
        margin: auto;
        margin-bottom: 56px;
    }

    .footer-internal-nav {
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media screen and (max-width: 374px) {
    .footer-nav {
        width: 280px;
    }

    
}