.patron-container {
    padding: 120px 195px 0 195px;
    overflow: hidden;
    background: #C2C7EF;


    @media screen and (max-width: 1920px) {
        padding: 120px 195px 0 195px;
    }
    @media screen and (max-width: 1365px) {
        padding: 80px 64px 0 64px;
    }
    @media screen and (max-width: 767px) {
        padding: 56px 24px 0 24px;
    }
    @media screen and (max-width: 374px) {
        padding: 48px 24px 0 24px;
    }
}

.patron-box {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.patron-content {
    margin-bottom: 276px;

    @media screen and (max-width: 1920px) {
        margin-bottom: 200px;
    }
    @media screen and (max-width: 1365px) {
        margin-bottom: 132px;
    }
    @media screen and (max-width: 766px) {
        margin-bottom: 48px;
    }
}


.patron-content__text {
    margin-top: 24px;
    margin-bottom: 72px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;

    @media screen and (max-width: 1920px) {
        margin-top: 24px;
        margin-bottom: 72px;    
    }
    @media screen and (max-width: 1365px) {
        font-size: 17px;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 48px;   
    }
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 24px;   
    }
    @media screen and (max-width: 374px) {
        margin-top: 8px;
        margin-bottom: 24px;   
    }
}


.patron-content__text ol {
    padding-inline-start: 25px;
}


.patron-content__text li {
    padding-left: 15px;
}

.patron-right {
    display: flex;
    height: 50px;
    justify-content: left;
    align-items: start;

    @media screen and (max-width: 767px) {
        height: 420px;
        justify-content: center;
    }
}

.patron-widget-container {
    width: 410px;
    height: 845px;
    background-image: url("./patronWidget.svg");
    background-size: cover;

    @media screen and (max-width: 1365px) {
        width: 286px;
        height: 594px;
    }
}

