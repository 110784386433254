.welcome-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 187px 120px;

    @media screen and (max-width: 1365px) {
        padding: 80px 40px;
    }

    @media screen and (max-width: 767px) {
        padding: 24px 24px 56px 24px;
    }
}

.welcome-subcontainer {
    display: flex;
    justify-content: space-between;
    max-width: 1278px;
    align-items: center;

    @media screen and (max-width: 1365px) {
        flex-direction: column;
    }
}

.welcome-content {
    display: flex;
    flex-direction: column;

    order: 1;

    @media screen and (max-width: 1365px) {
        order: 2;
    }

    @media screen and (max-width: 767px) {}

    align-items: flex-start;
}

.welcome-content__header {
    font-family: "PP Telegraf Ultrabold", sans-serif;
    font-size: 80px;
    font-weight: 800;
    line-height: 76px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 36px;

    @media screen and (max-width: 1365px) {
        font-size: 64px;
        line-height: 68px;
        text-align: center;
        margin-bottom: 36px;
    }

    @media screen and (max-width: 767px) {
        font-size: 38px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 24px;
    }
}

.welcome__button-divider {
    width: 24px;
}

.welcome-content__flow {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1365px) {
        flex-wrap: wrap;
        justify-content: center;
    }

}

.welcome-content__header-and-flow {
    display: flex;
    flex-direction: column;

    margin-bottom: 80px;

    @media screen and (max-width: 1365px) {
        margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
}

.flow__item {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    padding: 14px 0px;
    height: 62px;
    margin-right: 8px;
}

.flow__invest {
    width: 122px;
    background-image: url('./frame1.svg');
    background-repeat: no-repeat;
}

.flow__donate {
    width: 220px;
    background-image: url('./frame2.svg');
    background-repeat: no-repeat;
}

.flow__keep {
    width: 235px;
    background-image: url('./frame3.svg');
    background-repeat: no-repeat;
}

.flow__arrow {
    width: 36px;
    height: 15px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-image: url('./arrow.svg');
}

.welcome-content__buttons {
    display: flex;
    gap: 24px;
    justify-content: left;
    align-items: center;
    width: inherit;
    background: #C2C7EF;
    padding: 24px;
    border-radius: 40px;
    max-width: 696px;
    box-sizing: border-box;

    margin-top: 80px;

    @media screen and (max-width: 1365px) {
        max-width: 100%;
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        gap: 20px;
    }

    @media screen and (max-width: 375px) {
        gap: 16px;
    }
}

.welcome-picture {
    height: 512px;
    width: 497px;
    order: 2;

    @media screen and (max-width: 1919px) {
        height: 400px;
        width: 380px;
    }

    @media screen and (max-width: 1365px) {
        order: 1;
        margin-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
        order: 1;
        height: 310px;
        width: 295px;
        margin-bottom: 24px;
    }

    @media screen and (max-width: 374px) {
        order: 1;
        height: 202px;
        width: 192px;
    }

    background-image: url('./flowerNew.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.info-button {
    max-width: 315px;
    font-family: "PP Telegraf Ultrabold", sans-serif;
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0.4px;
    text-align: left;

    @media screen and (max-width: 1365px) {
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        max-width: 100%;
    }

    @media screen and (max-width: 375px) {
        font-size: 19px;
        line-height: 20px;
    }
}