header {
}
.header-container {
    height: 60px;
    padding: 14px 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1919px) {
        padding: 14px 48px;
    }
    @media screen and (max-width: 1365px) {
        padding: 14px 24px;
    }
}

.header-logo {
    width: 105px;
    height: 35px;
    background-image: url('../../drizzleLogo.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.header-middle__internal-nav {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.4000000059604645px;

    display: flex;
    width: 646px;
    justify-content: space-between;

    @media screen and (max-width: 1365px) {
        display: none;
    }
}

.internal-nav-item {
    cursor: pointer;
}

.header-right {
    display: flex;
    align-items: center;
}

.burger-menu-switch {
    cursor: pointer;
    display: none;
    
    @media screen and (max-width: 1365px) {
        display: flex;
        align-items: center;
        position: relative;
    
        height: 16px;
        width: 24px;
        margin-left: 24px;
    }
}

.burger-menu-switch div {
    height: 2px;
    width: 100%;
    transform: scale(1);
    background-color: black;
}

.burger-menu-switch::before, .burger-menu-switch::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    transition: all 0.3s ease 0s;
    background-color: black;
}

.burger-menu-switch::before {
    top: 0;
} 

.burger-menu-switch::after {
    bottom: 0;
}

.burger-active div {
    transform: scale(0);
}

.burger-active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
}

.burger-active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
}

.header-fullscreen {
    transition: all 0.2s ease 0s;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: #FFF6E3;
}

.no-scroll {
    overflow: hidden;
    width: 100%;
  }