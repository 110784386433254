@import '../../styles/style.scss';

.modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;

    background-color: $gray-60;
    backdrop-filter: blur(3px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    box-sizing: border-box;
    padding: 0 16px;
}

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 16px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: $shadow;
    animation: ani 0.3s forwards;
}

.cross {
    position: absolute;
    padding: 0;
    outline: 0;
    top: 36px;
    right: 36px;
    color: $gray-20;
    cursor: pointer;
    z-index: 100;
}

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}