@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

// primary
$primary-1: #492CBD;
$primary-2: #4428B0;

//additional
$additional-4: #FFF5EC;
$additional-5: #F5E7EA;
$additional-6: #E8EAF5;
$additional-7: #DCEDDD;


// drizzle 
$drizzle-color: #3500FF0A;

// common
$white: #ffffff;
$black: #18171BF5;

// surface
$surface-3: #EFE8E2;

// gray
$gray-0: #000;
$gray-10: rgba(24, 23, 27, 0.96);
$gray-20: rgba(24, 23, 27, 0.84);
$gray-30: rgba(24, 23, 27, 0.76);
$gray-40: rgba(24, 23, 27, 0.68);
$gray-50: rgba(24, 23, 27, 0.60);
$gray-60: rgba(24, 23, 27, 0.48);
$gray-70: rgba(24, 23, 27, 0.36);
$gray-80: rgba(24, 23, 27, 0.28);
$gray-90: rgba(24, 23, 27, 0.20);
$gray-95: rgba(24, 23, 27, 0.12);
$gray-99: rgba(24, 23, 27, 0.08);
$gray-100: rgba(24, 23, 27, 0.04);

// purple
$purple-0: #181230;
$purple-10: rgba(47, 39, 80, 0.96);
$purple-20: rgba(47, 39, 80, 0.84);
$purple-30: rgba(47, 39, 80, 0.76);
$purple-40: rgba(47, 39, 80, 0.68);
$purple-50: rgba(50, 41, 86, 0.60);
$purple-60: rgba(57, 47, 100, 0.48);
$purple-70: rgba(58, 47, 103, 0.36);
$purple-80: rgba(65, 51, 120, 0.28);
$purple-90: rgba(75, 56, 148, 0.20);
$purple-95: rgba(82, 54, 190, 0.12);
$purple-99: rgba(79, 43, 217, 0.08);
$purple-100: rgba(53, 0, 255, 0.04);

// shadow
$shadow: 0 4px 16px 1px rgba(0, 26, 52, .16);
$border: 2px solid #3c3b3b87;

// breakpoint
$max-width: 1200px;
$min-width: 500px;
$medium-screen: 800px;

$screen-mobile-min: 350px;
$screen-mobile-mid: 500px;
$screen-mobile-mid-plus: 650px;
$screen-mobile-max: 800px;
$screen-web-min: 1280px;
$screen-web-mid: 1440px;
$screen-web-max: 1980px;

// fonts
$mainFont: 'PPTelegraf', sans-serif;
$secondFont: 'PPTelegraf', sans-serif;

$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;

$font-size-base: 16px;
$font-size-huge: 64px;
$font-size-big: 32px;
$font-size-medium: 24px;
$font-size-small: 14px;
$font-size-tiny: 12px;

$line-height-huge: 60px;
$line-height-big: 35px;
$line-height-medium: 30px;
$line-height-small: 24px;
$line-height-tiny: 16px;

$animation: ani 1s forwards;

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}