.wrapper {
    width: 100%;
    max-width: 686px;
    padding: 80px 64px 64px 64px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 32px;
    background: #FFF0D3;
    border-radius: 24px;
}

.text {
    font-family: "PP Telegraf Ultrabold", sans-serif;
    font-size: 64px;
    font-weight: 800;
    line-height: 68px;
    text-align: center;

}

.description {
    font-family: "PP Telegraf", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.emoji {
    font-size: 56px;
}

@media screen and (max-width: 560px) {
    .text {
        font-size: 32px;
        font-weight: 800;
        line-height: 36px;
    }

    .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }

}

@media screen and (max-width: 350px) {
    .wrapper {
        padding: 60px 44px 44px 44px;
    }
}