
@font-face {
    font-family: PP Telegraf Regular;
    src: url("/public/fonts/PPTelegraf-Regular.woff2") format("woff2"),
         url("/public/fonts/PPTelegraf-Regular.woff")  format("woff"),
         url("/public/fonts/PPTelegraf-Regular.ttf")   format("truetype");
}

@font-face {
    font-family: PP Telegraf Semibold;
    src: url("/public/fonts/PPTelegraf-Semibold.woff2") format("woff2"),
         url("/public/fonts/PPTelegraf-Semibold.woff")  format("woff"),
         url("/public/fonts/PPTelegraf-Semibold.ttf")   format("truetype");
}

@font-face {
    font-family: PP Telegraf Ultrabold;
    src: url("/public/fonts/PPTelegraf-Ultrabold.woff");
    src: url("/public/fonts/PPTelegraf-Ultrabold.woff2") format("woff2"),
         url("/public/fonts/PPTelegraf-Ultrabold.woff")  format("woff"),
         url("/public/fonts/PPTelegraf-Ultrabold.ttf")   format("truetype");
}

body {
    font-family: "PP Telegraf Regular", sans-serif;
}
